import { useAtom } from "jotai";
import React, { FC, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { searchQueryState } from "../../../atoms/atoms";
import { RemoveSearchButton } from "../../Buttons";
import { Loupe } from "../../Icons";
import { Container, SearchInputElement, SearchInputLabel } from "./HeaderSearchInput.styles";
import { useSearchParams } from "react-router-dom";

const HeaderSearchInput: FC = () => {
  const [searchQuery, setSearchQuery] = useAtom(searchQueryState);
  const [value, setValue] = useState("");
  const [_, setSearchParams] = useSearchParams();

  const deletingValue = () => {
    setValue("");
    setSearchQuery("");
  };

  useEffect(() => deletingValue, []);

  const debounced = useDebouncedCallback((value) => {
    setSearchParams({ page: String(1) });
    setSearchQuery(value);
  }, 500);

  return (
    <Container>
      <SearchInputLabel loupePosition={searchQuery === "" ? "left" : "right"}>
        <Loupe />

        {/* {searchQuery ? (
          <MatchesCount>1/{searchResult.length}</MatchesCount>
        ) : null} */}

        {searchQuery ? <RemoveSearchButton onClick={deletingValue} /> : null}

        <SearchInputElement
          placeholder="Введите запрос для поиска"
          onChange={(evt) => {
            setValue(evt.target.value);
            debounced(evt.target.value);
          }}
          value={value}
          autoFocus
        />
      </SearchInputLabel>

      {/* {searchQuery ? (
        <PrevNextButtons>
          <Button>
            <ArrowDown />
          </Button>
          <Button>
            <ArrowUp />
          </Button>
        </PrevNextButtons>
      ) : null} */}
    </Container>
  );
};

export default HeaderSearchInput;
